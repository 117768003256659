<template>
  <div class="page-main">
    <b-container>
      <h1>{{page.title[lang]}}</h1>
      <div v-html="page.html[lang]"></div>
    </b-container>
  </div>
</template>
<script>
export default {
    props: ["link", "lang"],
    data() {
      return { 
        page: ""
      }
    },
    created() { 
      this.page = this.pages.find(page => page.link === this.link)
    },
    computed: {
      pages() {
        return this.$store.state.Pages.pages
      }
    }
}
</script>
<style lang="scss">
.page-main {
  flex-grow: 1;
  background-color: white;
}
</style>